import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';
import {DynaSentry, EConsoleType} from "dyna-sentry";

import {AppName} from "dyna-interfaces";

import {App, appConfig} from "dyna-travel-app-dev-v001";

import reportWebVitals from './reportWebVitals';

import './index.css';

// package info
const appPackage_ = require('../package.json');
const [major, minor, patch] = appPackage_.version.split('.').map(Number);

// create the appName object (for Sentry's tags and debug info)
const appName = new AppName({
    machineName: 'browser',
    projectName: appConfig.product,
    end: "FE",
    mode: (() => {
        if (window.location.host.indexOf('localhost') === 0) return "DEV";
        if (window.location.host.indexOf('127.0.0.1') === 0) return "DEV";
        if (window.location.host.indexOf('-staging') > -1) return "STAGE";
        return "PROD";
    })(),
    version: {
        major,
        minor,
        patch: Number(String(patch).split('-')[0]),
        flag: String(patch).split('-').slice(1).join('-'),
    },
});

// Debug info of the app
window["_dynaTravel"] = {
    app: appName,
    packageName: appPackage_.name,
    packageLabel: appPackage_.label,
    packageDescription: appPackage_.description,
};

// Instantiate the Sentry
Sentry.init({
    dsn: "https://5fa316095aee4c37934a7ca14da805e4@sentry.io/2316895",
});

// Instantiate the DynaSentry with the Sentry's instance
new DynaSentry({
    Sentry,
    captureConsole: {
        consoleTypes: [
            EConsoleType.ERROR,
            EConsoleType.WARN,
            EConsoleType.DEBUG,
        ],
        setScope: scope => {
            scope.setTag('FullAppInstance', appName.name);
            scope.setTag('ProjectName', appName.getConfig().projectName);
            scope.setTag('PackageName', appPackage_.name);
            scope.setTag('MachineName', appName.getConfig().machineName);
            scope.setTag('MainMode', appConfig.mode);
            scope.setTag('DetailedMode', appName.getConfig().mode);
            scope.setTag('End', appName.getConfig().end);
            scope.setTag('Version', appName.version);
        },
        filterOut: {
            caseSensitive: false,
            texts: [
                // 'INVESTIGATE2665',
                '[Violation]',
                'Warning: componentWillUpdate has been renamed',
                'Warning: componentWillReceiveProps has been',
                'Warning: componentWillMount has been renamed',
            ],
        },
    },
});


ReactDOM.render(
    <App/>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
